export const environment = {
  apiBaseUrl: 'https://api-dev.beeoclock.com',
  apiCrmUrl: 'https://crm.dev.beeoclock.com',
  firebase: {
    apiKey: "AIzaSyDpqktdOQyijnyCaiaOl3_DxUQhTu3PjUg",
    authDomain: "beeoclock-develop.firebaseapp.com",
    projectId: "beeoclock-develop",
    storageBucket: "beeoclock-develop.appspot.com",
    messagingSenderId: "957741407419",
    appId: "1:957741407419:web:4c7e90f6e06f697daa5d9e",
    measurementId: "G-CS1RTNPTP2"
  },
  production: false,
  config: {
    language: 'en',
    host: 'https://panel.dev.beeoclock.com',
    consultationLink: 'https://beeoclock.com/office',
    demoAccount: {
      panelUrl: 'https://panel.dev.beeoclock.com/identity',
      login: 'demo@beeoclock.com',
      password: 'ItIckBeRSOLDENZYGosicirE'
    }
  },
};
