<section
  id="tariffs"
  class="flex w-full justify-center items-center lg:min-h-[810px] phone:py-10 phone:px-5 lg:px-5 xl:px-0"
>
  <div class="flex flex-col w-full max-w-[1080px] gap-10 phone:gap-3">
    <div class="flex w-full items-center phone:flex-col phone:items-start phone:gap-2.5">
      <div class="w-full flex flex-col px-2.5">
        <h2 class="font-bold text-[40px]" i18n>Tariffs</h2>
        <div class="flex [&>p]:text-base phone:portrait:flex-col gap-[2px]">
          <p class="font-normal" i18n>Choose the best option for your business.</p>
          <p class="font-semibold" i18n>The first 14 days are free</p>
        </div>
      </div>
      <div class="flex shadow-sm h-[40px] rounded-[66px]">
        <button
          id="monthly-button"
          class="text-sm font-semibold font-inter px-5 py-[5px] rounded-[66px] h-[40px] transition-colors duration-300"
          [ngClass]="{ 'bg-[#E5E5E5]': subscriptionType() === billingCycleEnum.monthly }"
          (click)="setSubscriptionType(billingCycleEnum.monthly)"
          i18n
        >
          Monthly
        </button>
        <button
          id="annual-button"
          class="text-sm font-semibold font-inter px-5 py-[5px] rounded-[66px] h-[40px] transition-colors duration-300"
          [ngClass]="{ 'bg-[#E5E5E5]': subscriptionType() === billingCycleEnum.yearly }"
          (click)="setSubscriptionType(billingCycleEnum.yearly)"
          i18n
        >
          Annual
        </button>
      </div>
    </div>

    <div class="flex [&>div]:rounded-2xl gap-5 [&>div]:px-5 [&>div]:py-3 [&>div>h2]:text-2xl [&>div>h2]:font-bold
                  [&>div>h2]:text-[#FFD429] [&>div]:w-full phone:flex-col">
      @for (item of items(); track item._id) {


        <div
          class="max-w-sm flex justify-between min-w-[300px] flex-col h-[600px] transition-transform duration-300 bg-white shadow-lg rounded-2xl gap-5 px-3 py-3">
          <div class="flex flex-col">
            <div class="flex px-2 justify-between items-center mb-1">
              <h2 class="text-2xl font-bold text-[#FFD429] uppercase">
                {{ item.type }}
              </h2>
              @if (item.type === typeTariffPlanEnum.Free) {
                <p class="font-light text-xs" i18n>No card required</p>
              }
            </div>
            <div class="flex justify-center">
              <div class="flex flex-col w-[250px]">
                <div class="flex flex-col justify-center">
                  @if (subscriptionType() === billingCycleEnum.yearly) {
                    @if (item.type === typeTariffPlanEnum.Free) {
                      <div class="h-7 portrait:phone:hidden"></div>
                    }
                  }
                  @if (item.type !== typeTariffPlanEnum.Free) {

                    @if (subscriptionType() === billingCycleEnum.yearly) {
                      <div class="flex gap-3">
                        <p class="font-light text-base line-through">
                          {{ (item.prices[0].values[0].beforeDiscount / 12) | number: '1.0-0' }}
                          {{ item.prices[0].currency | currencyCode }}
                        </p>
                        <p class="rounded-2xl text-[#FFFFFF] text-base font-light px-[6px] py-[2px] bg-[#DA0606]">
                          {{ item.prices[0].values[0].discountPercentage }}%
                        </p>
                      </div>
                    }
                  }
                  <div class="flex items-center gap-1">
                    <p class="flex font-bold text-[64px] items-baseline gap-1">
                      @if (subscriptionType() === billingCycleEnum.yearly) {
                        {{ (item.prices[0].values[0].afterDiscount / 12) | number: '1.0-0' }}
                      } @else {
                        {{ item.prices[0].values[0].afterDiscount | number: '1.0-0' }}
                      }
                      <span class="font-bold mb-1 text-2xl mr-1.5">
													{{ item.prices[0].currency | currencyCode }}
												</span>
                    </p>
                    <div class="flex flex-col">
                      @if (item.type !== typeTariffPlanEnum.Free) {
                        @if (billingCycleEnum.yearly === subscriptionType()) {
                          <span
                            class="font-medium text-sm text-[#CACACA]">per month</span>
                        }
                      }
                    </div>
                  </div>
                </div>
                <ul
                  class="[&>li]:text-sm [&>li]:font-medium [&>li]:mb-1 [&>li]:h-[26px] [&>li]:flex [&>li]:items-center">
                  <li class="font-bold">
                    <ng-icon name="bootstrapCheck" size="2em"/>
                    {{ getSpecialistLimitLabel(item.type) }}
                  </li>
                  @for (feature of item.features; track feature) {
                    <li>
                      <ng-icon name="bootstrapCheck" size="2em"/>
                      {{ feature | featureTranslate }}
                    </li>
                  }
                </ul>
              </div>
            </div>
          </div>
          <a
            [href]="crmLogin"
            target="_blank"
            class="bg-[#FFD429] font-bold text-xl py-4 px-5 transition-all duration-150 ease-in-out active:scale-95
                         hover:bg-[#FFC800] rounded-[10px] uppercase w-full text-center block"
            i18n
          >
            Get Started
          </a>
        </div>

      }
    </div>
  </div>
</section>
