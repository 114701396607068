
  <img ngSrc="/assets/img/left_men.svg" priority alt="img" class="absolute left-[-17.5%] bottom-[-0.6%] hidden xl:block z-10"
       height="456" width="250">
  <img ngSrc="/assets/img/right_men.svg" priority alt="img" class="absolute right-[-17.5%] bottom-[-0.6%] hidden xl:block z-10"
       height="469" width="296">
  <div
    class="self-stretch grow shrink basis-0 bg-[#FFFFFF] rounded-2xl justify-start items-start gap-[50px] flex phone:flex-col">
    <div
      class="grow shrink basis-0 self-stretch px-[30px] py-5 flex-col justify-between items-start inline-flex phone:gap-[70px] phone:shadow-sm rounded-2xl">
      <div class="self-stretch  flex-col justify-start items-start gap-5 flex">
        <div class="self-stretch flex-col justify-start items-start flex">
          <div class="text-black text-8xl font-bold leading-tight phone:text-[64px]" i18n>
            Contact
          </div>
          <div class="self-stretch flex-col justify-start items-start flex">
            <div class="self-stretch text-black text-sm font-bold leading-tight" i18n>
              Do you have any questions or suggestions?
            </div>
            <div class="self-stretch text-[#606060] text-xs font-normal" i18n>
              If you have any additional questions about using Bee o’clock or would like to share ideas for
              improving the service,
              we are always ready to listen! Contact us in any convenient way:
            </div>
          </div>
        </div>
        <a [href]="'mailto:' + email" class="justify-start items-center gap-2.5 inline-flex">
          <ng-icon name="bootstrapEnvelope"/>
          <div class="text-black text-sm font-semibold tracking-tight">
            {{ email }}
          </div>
        </a>
      </div>
      <div class="self-stretch flex-col justify-start items-start gap-5 flex">
        <div class="self-stretch  flex-col justify-start items-start gap-5 flex">
          <div class="self-stretch  flex-col justify-start items-start flex">
            <div class="self-stretch text-black text-sm font-bold leading-tight" i18n>
              Social networks
            </div>
            <div class="self-stretch text-[#606060] text-xs font-normal" i18n>
              Join our community to be the first to receive useful tips, news, and promotional offers:
            </div>
          </div>
          <div
            class="self-stretch justify-between items-start inline-flex phone:flex-col phone:grid phone:grid-cols-2 phone:gap-x-5 phone:gap-y-[6px]">
            <a href="https://www.instagram.com/beeoclock.biz/" target="_blank"
               class="justify-start items-center gap-1.5 flex">
              <ng-icon name="bootstrapInstagram"/>
              <div
                class="text-black text-xs font-normal underline underline-offset-2 tracking-tight flex items-center">
                <ng-icon name="bootstrapAt" size="1em" class=""/>
                beeoclock.biz
              </div>
            </a>
            <a href="https://m.facebook.com/@beeoclock.biz/?wtsid=rdr_0eM1YyzCYFTDfjBGU&hr=1" target="_blank"
               class="justify-start items-center gap-1.5 flex">
              <ng-icon name="bootstrapFacebook"/>
              <div class="text-black text-xs font-normal underline underline-offset-2 tracking-tight">
                beeoclock.biz
              </div>
            </a>
            <a href="https://x.com/bee_o_clock?s=21" target="_blank"
               class="justify-start items-center gap-1.5 flex">
              <ng-icon name="bootstrapTwitterX"/>
              <div class="text-black text-xs font-normal underline underline-offset-2 tracking-tight">
                bee_o_clock
              </div>
            </a>
            <a href="https://www.linkedin.com/company/beeoclock/" target="_blank"
               class="justify-start items-center gap-1.5 flex">
              <ng-icon name="bootstrapLinkedin" class="bg-black"/>
              <div class="text-black text-xs font-normal underline underline-offset-2 tracking-tight">
                beeoclock
              </div>
            </a>
          </div>
        </div>
        <div class="self-stretch text-[#606060] text-xs font-normal" i18n>
          Follow us on social media and be the first to know about updates and new features of Bee o’clock.
        </div>
      </div>
    </div>
    <div class="grow shrink basis-0 self-stretch justify-start items-center gap-2.5 flex">
      <div class="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-3 flex">
        <div
          class="self-stretch grow shrink basis-0 px-10 pt-[50px] pb-5 bg-[#f3f3f3] rounded-2xl flex-col justify-between items-end flex">
          <div class="self-stretch flex-col justify-start items-start gap-[31px] flex">
            <div class="self-stretch flex-col justify-start items-start flex">
              <div class="self-stretch h-5 text-black text-sm font-bold leading-tight" i18n>
                Feedback form
              </div>
              <div class=" text-[#606060] text-xs font-normal" i18n>
                If you prefer to write directly here on the website, fill out the form below - we will try to
                respond as quickly as possible, usually within 24 hours.
              </div>
            </div>
            <div class="self-stretch flex-col justify-start items-start flex phone:relative">
              <form [formGroup]="contactForm" (ngSubmit)="onSubmit()"
                    class="flex flex-col w-full [&>div]:flex [&>div]:flex-col [&>div>input]:p-0 gap-8 [&>div>input]:h-[33px]
                                 [&>div>input]:bg-[#f3f3f3] [&>div>input]:border-0 [&>div>input]:border-b-[1px] [&>div>label]:font-semibold
                                 [&>div>label]:text-sm [&>div>textarea]:border-0 [&>div>textarea]:border-b-[1px] [&>div>textarea]:bg-[#f3f3f3]"
              >
                <div class="relative">
                  <label for="name"
                         class="after:content-['*'] after:text-rose-600"
                         [ngClass]="{'text-rose-600': isInvalid('name')}" i18n>
                    Name
                  </label>
                  <input type="text"
                         id="name"
                         formControlName="name"
                         placeholder="Name"
                         i18n-placeholder="@@placeholderName"
                         class="focus:shadow-none focus:outline-none focus:ring-0 focus:border-beeColor-500 placeholder:text-[12px] text-[#606060]"/>
                </div>
                <div class="relative">
                  <label for="email"
                         class="after:content-['*'] after:text-rose-600"
                         [ngClass]="{'text-rose-600': isInvalid('email')}" i18n>
                    Email
                  </label>
                  <input type="text"
                         id="email"
                         formControlName="email"
                         placeholder="E-mail"
                         i18n-placeholder="@@placeholderEmail"
                         class="focus:shadow-none focus:outline-none focus:ring-0 focus:border-beeColor-500 placeholder:text-[12px] text-[#606060]"/>
                  @if (isInvalid('email')) {
                    <div class="absolute text-xs bottom-[-35%]" [ngClass]="{'text-rose-600': isInvalid('email')}"
                         i18n>
                      Incorrect Email
                    </div>
                  }
                </div>
                <div>
                  <label for="phone" i18n>Phone</label>
                  <input type="tel"
                         id="phone"
                         #phoneInput
                         formControlName="phone"
                         (input)="sanitizePhoneInput()"
                         (keypress)="preventNonNumeric($event)"
                         class="w-full border-0 border-b-[1px] bg-[#f3f3f3] focus:shadow-none focus:outline-none focus:ring-0 focus:border-beeColor-500"/>
                </div>
                <div class="relative">
                  <label for="subject"
                         class="phone:portrait:max-w-[320px] lg:max-w-[320px]"
                         [ngClass]="{'text-rose-600': isInvalid('subject')}" i18n>
                         <span class="after:content-['*'] after:text-rose-600">
                                Message subject
                         </span>
                    <span class="text-[12px] font-normal text-[#606060]">
                            (for example: "Questions about tariffs", "Technical support")
                          </span>
                  </label>
                  <input type="text"
                         id="subject"
                         formControlName="subject"
                         placeholder="Message subject"
                         i18n-placeholder="@@placeholderSubject"
                         class="focus:shadow-none focus:outline-none focus:ring-0 focus:border-beeColor-500 placeholder:text-[12px] text-[#606060]"/>
                  @if (isInvalid('subject')) {
                    <div class="absolute text-xs bottom-[-25%]" [ngClass]="{'text-rose-600': isInvalid('subject')}"
                         i18n>
                      Minimum 3 character
                    </div>
                  }
                </div>
                <div class="relative">
                  <label for="message"
                         [ngClass]="{'text-rose-600': isInvalid('message')}" i18n>
                          <span class="after:content-['*'] after:text-rose-600">
                            Message text
                          </span>
                    <span class="text-[12px] font-normal text-[#606060]">
                            (please indicate your request or suggestion)
                          </span>
                  </label>
                  <textarea maxlength="1000" type="text"
                            #messageTextarea
                            id="message"
                            (input)="autoResize()"
                            formControlName="message"
                            placeholder="Message text"
                            i18n-placeholder="@@placeholderMessageText"
                            class="focus:shadow-none focus:outline-none focus:ring-0 focus:border-beeColor-500 placeholder:text-[12px] text-[#606060] pl-0"></textarea>
                  @if (isInvalid('message')) {
                    <div class="absolute text-xs bottom-[-25%]" [ngClass]="{'text-rose-600': isInvalid('message')}"
                         i18n>
                      Minimum 10 character
                    </div>
                  }
                </div>
                <button class="self-stretch px-7 py-4 bg-[#000] rounded-[11px] justify-center items-center gap-2.5
                               inline-flex cursor-pointer text-[#ffff] text-xl font-bold tracking-tight
                               transition-all duration-150 ease-in-out active:scale-95" i18n
                >
                  Send
                </button>
              </form>
              @if (isPopupOpen()) {
                <div class="fixed inset-0 bg-[#00000033] flex items-center justify-center z-10"></div>
                <div class="bg-[#FFFFFF] z-20 flex flex-col rounded-[20px] w-full max-w-[528px] max-h-[157px]
                             phone:portrait:max-h-[227px] px-6 py-4 shadow-lg fixed left-1/2 top-1/2
                             transform -translate-x-1/2 -translate-y-1/2phone:portrait:max-w-[335px]"
                >
                  <div (click)="closePopup()" class="flex justify-end cursor-pointer">
                    <ng-icon name="bootstrapXLg"/>
                  </div>
                  <div class="flex justify-center items-center gap-3.5 phone:portrait:flex-col">
                    <div>
                      <ng-icon size="3em" name="bootstrapCheckCircleFill"/>
                    </div>
                    <div class="text-center">
                      <span class="font-bold text-xl phone:text-base" i18n>
                        The form has been successfully submitted!
                      </span>
                      <p class="text-sm font-normal" i18n>
                        We will contact you shortly.
                      </p>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
