<div class="wrapper w-full flex justify-center flex-col items-center">
  <div class="w-full max-w-[1440px]">
    <nav class="phone:landscape:px-5 max-lg:landscape:px:5 phone:px-5 w-full lg:px-5 xl:px-0 bg-[#FFFFFF] fixed md:static z-20
                text-black flex justify-between items-center h-[70px] md:h-[90px] max-w-[1200px] mx-auto lg:mb-[58px] md:mb-0"
    >
      <div class="h-[60px] md:h-[70px] justify-between items-center w-full flex">
        <div class="flex items-center w-[200px]">
          <a href="#" id="logo-link" class="flex gap-[14px]">
            <img ngSrc="assets/new_logo.svg" alt="Bee O`clock Logo" height="38" width="38">
            <img ngSrc="assets/logo_text.svg" class="phone:hidden lg:block max-lg:hidden" alt="Bee O`clock"
                 height="38" width="119"
            >
          </a>
        </div>
        <div class="flex justify-center lg:hidden w-full">
          <img ngSrc="assets/logo_text.svg" alt="Bee O`clock" height="38" width="119">
        </div>
        <div class="flex lg:hidden w-[200px] justify-end">
          <button (click)="toggleMobileMenu()" id="mobile-menu-button" aria-label="close-open"
                  class="text-black focus:outline-none h-[100%] flex">
            @if (isMobileMenuOpen) {
              <ng-icon name="bootstrapXLg"/>
            } @else {
              <ng-icon name="bootstrapThreeDots"/>
            }
          </button>
        </div>
        <div class="phone:hidden md:flex gap-[20px] font-medium">
          @for (item of menuItems; track item.id) {
            @if (item.useCase === MenuUseCase.Desktop || item.useCase === MenuUseCase.Both) {
              <a [href]="item.link" id="{{item.name}}-link-nav"
                 class="hover:bg-main-hover hover:bg-opacity-20 transition-all duration-400 ease-in-out px-5 py-2.5 rounded-[40px]"
                 i18n
              >
                {{ item.name }}
              </a>
            }
          }
        </div>
        <div class="phone:hidden justify-end hidden md:flex font-medium w-[200px]">
          <a [href]="crmLogin" target="_blank"
             class="hover:bg-main-hover hover:bg-opacity-20 transition-all duration-400 ease-in-out px-5 py-2.5 rounded-[40px] "
             i18n>
            Login
          </a>
        </div>
        @if (isMobileMenuOpen) {
          <div
            class="fixed inset-0 bg-[#FFFFFF] z-30 mt-[70px] overflow-auto transition-all duration-400 ease-in-out">
            <ul
              class="flex flex-col items-center px-5 pt-4 [&>a]:flex [&>a]:items-center [&>a]:h-[51px] [&>a]:w-full
                     [&>a]:border-b [&>a]:border-[#F4F4F5]"
            >
              @for (item of menuItems; track item.name) {
                <a [href]="item.link" (click)="toggleMobileMenu()" id="{{item.name}}-mobile-link" class="hover:text-yellow-500 font-medium"
                   [attr.target]="item.name === 'Try a demo account' ? '_blank' : null" i18n>
                  <li>
                    {{ item.name }}
                  </li>
                </a>
              }
            </ul>
          </div>
        }
      </div>
    </nav>

    <section class="flex w-full justify-center mt-[90px] md:mt-0 phone:mb-7 xl:min-h-[810px]">
      <div class="flex flex-col landscape:flex-row md:flex-row items-center w-full max-w-[1200px] max-lg:px-5 landscape:gap-2.5
                  phone:landscape:px-5 phone:px-5 lg:px-5 xl:px-0"
      >
        <div class="flex lg:hidden relative w-full lg:mb-[20px] mb-0">
          <img [src]="'assets/img/local_img/main_mob_'+ localeSuffix + '.webp'" alt="Bee O`clock service details image" height="286"
               width="335">
        </div>
        <div class="text-wrapper mx-auto flex flex-wrap items-center w-full sm:max-w-[100%] lg:max-w-[40%] sm:px-0">
          <div class="max-w-[469px] mb-[30px] md:mb-10 landscape:h-full">
            <p class="font-bold leading-[34px] text-[40px] phone:text-[32px] landscape:text-[30px] landscape:mb-2.5"
               i18n>
              Online appointment booking for clients.
            </p>
            <p class="font-medium md:text-[20px]" i18n>
              A simple and effective tool for managing customer records in beauty salons and other businesses
            </p>
          </div>
          <a [href]="crmRegister" target="_blank">
            <button type="button" id="try-free-button"
                    class="font-bold text-[20px] py-4 px-7 bg-[#FFD429] rounded-[60px] w-full md:w-auto hover:bg-[#FFC800]
                          transition-all  duration-150 ease-in-out active:scale-95"
                    i18n>
              Try it for free
            </button>
          </a>
        </div>
        <div class="relative hidden lg:block">
          <img [src]="'assets/img/local_img/group_' + localeSuffix + '.webp'" alt="Bee O`clock service details image" height="715" width="721">
          <img class="absolute top-[30%] right-[90%]" ngSrc="assets/img/vector_1.png" alt="Bee O`clock service schedule"
               height="78" width="40">
          <img class="absolute top-[67%]" ngSrc="assets/img/vector_2.png" alt="line" height="48" width="78">
          <img class="absolute top-[4%] right-[67%]" [src]="'assets/img/local_img/service_details_'+ localeSuffix + '.webp'" alt="line" height="137"
               width="200">
        </div>
      </div>
    </section>

    <section  id="services" class="flex w-full justify-center xl:min-h-[840px]">
      <div class="flex w-full items-center phone:flex-col phone:px-5 lg:px-5 xl:px-0 gap-5 justify-center">
        <div class="flex">
          <img [src]="'assets/img/local_img/img_' + localeSuffix + '.webp'" alt="Bee O`clock service details">
        </div>
        <div class="text-4xl flex items-center flex-col">
          <div class="flex justify-start w-full mb-[30px] phone:flex-col">
            <h2 class="font-bold text-black mr-2 md:mr-2" i18n>Opportunities</h2>
            <h2 class="font-bold text-[#FFD429]">Bee o'clock</h2></div>
          <div class="flex">
            <ul class="flex flex-col gap-[20px] [&>li>div>h3]:font-bold [&>li>div>h3]:text-xl [&>li>div>p]:font-medium
                       [&>li>div>p]:text-[13px] [&>li>div]:max-w-[524px] leading-7 [&>li]:flex"
            >
              <li>
                <ng-icon name="bootstrapCheck" class="pr-6 phone:pr-10" size="1em"></ng-icon>
                <div>
                  <h3 i18n>Automate records</h3>
                  <p i18n>Forget about confusion with records. Our service automates customer onboarding processes,
                    allowing you to focus on your work, not on record administration.</p>
                </div>
              </li>
              <li>
                <ng-icon name="bootstrapCheck" class="pr-6 phone:pr-10" size="1em"/>
                <div>
                  <h3 i18n>Reminder to clients</h3>
                  <p i18n>Make sure your customers always remember their visit! Automatic SMS and email reminders will
                    ensure that your clients arrive on time and reduce the number of missed appointments.</p>
                </div>
              </li>
              <li>
                <ng-icon name="bootstrapCheck" class="pr-6 phone:pr-10" size="1em"/>
                <div>
                  <h3 i18n>Customized for your business</h3>
                  <p i18n>Make Bee o'clock a part of your brand! You can customize each interface of the service to your
                    style and brand, which allows you to individualize your workflows.</p>
                </div>
              </li>
              <li>
                <ng-icon name="bootstrapCheck" class="pr-6 phone:pr-10" size="1em"/>
                <div>
                  <h3 i18n>Manage your records online</h3>
                  <p i18n>All information is at your fingertips - you can access customer history, schedules, and
                    records from any device. You can make decisions anywhere and anytime.</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="flex w-full justify-center xl:min-h-[810px]">
      <div class="flex w-full max-w-[1040px] items-center phone:flex-col phone:px-5 lg:px-5 xl:px-0 justify-between">
        <div class="w-full flex flex-col">
          <h2 class="font-bold text-4xl text-black mb-[26px] phone:pt-12 phone:flex" i18n>How does it work?</h2>
          <div class="flex gap-5 flex-wrap [&>div]:bg-[#F4F4F4] [&>div]:rounded-[32px] [&>div]:flex [&>div]:max-w-[510px]
                      [&>div]:w-full [&>div]:h-[180px] [&>div>p]:font-bold [&>div>p]:text-[128px] [&>div>p]:text-[#CACACA]
                      [&>div>div>h3]:font-bold [&>div>div>h3]:text-x [&>div>div]:flex [&>div>div]:flex-col [&>div>div]:item-center
                      [&>div>div]:justify-center [&>div>div>p]:max-w-[268px] [&>div]:justify-around [&>div>div:last-child]:phone:hidden
                      [&>div>div>p]:phone:text-[16px] [&>div]:phone:h-[170px] [&>div>p]:phone:mr-2 [&>div>p]:flex
                      [&>div>p]:w-[90px] [&>div>p]:justify-center [&>div]:items-center [&>div>div>p]:text-sm [&>div>div]:phone:pr-[6px]"
          >
            <div>
              <p>1</p>
              <div>
                <h3 i18n>Registration on the platform</h3>
                <p i18n>Create an account: Fill in basic information about your business, add address and contact
                  details.</p>
              </div>
              <div><img ngSrc="../assets/icon/1.png" alt="registration" height="120" width="120"></div>
            </div>
            <div>
              <p>2</p>
              <div>
                <h3 i18n>Service settings</h3>
                <p i18n>Add your services: Specify the list of services, their duration and cost.</p>
              </div>
              <div><img ngSrc="../assets/icon/2.png" alt="service settings" height="120" width="120"></div>
            </div>
            <div>
              <p>3</p>
              <div>
                <h3 i18n>Customer invitations</h3>
                <p i18n>Get a unique link to your record page and share it via website, social media, or email.</p>
              </div>
              <div><img ngSrc="../assets/icon/3.png" alt="customer" height="120" width="120"></div>
            </div>
            <div>
              <p>4</p>
              <div>
                <h3 i18n>Online customer registration</h3>
                <p i18n>Convenient booking: Customers book a service and a convenient time online, without calling or
                  waiting.</p>
              </div>
              <div><img ngSrc="../assets/icon/4.png" alt="customer" height="120" width="120"></div>
            </div>
            <div>
              <p>5</p>
              <div>
                <h3 i18n>Managing records</h3>
                <p i18n>Control your schedule: Get notified about new recordings, view and manage your schedule in a
                  convenient interface.</p>
              </div>
              <div><img ngSrc="../assets/icon/5.png" alt="records" height="120" width="120"></div>
            </div>
            <div>
              <p>6</p>
              <div>
                <h3 i18n>Analysis and development</h3>
                <p i18n>Track your success: Analyze your record and customer activity statistics to improve your
                  service.</p>
              </div>
              <div><img ngSrc="../assets/icon/6.png" alt="analytics" height="120" width="120"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="flex w-full justify-center lg:min-h-[810px] phone:py-10 phone:px-5 lg:px-5 xl:px-0">
      <div class="flex w-full max-w-[1040px] justify-center flex-col items-center phone:flex-col phone:gap-7 gap-4">
        <div class="w-full flex justify-center relative">
          <img ngSrc="assets/img/demo.webp" alt="" height="569" width="926" priority class="phone:hidden">
          <iframe class="max-w-[609px] w-full h-[440px] phone:h-[230px] absolute top-[10%] rounded-2xl phone:relative"
                  src="https://www.youtube.com/embed/Z5L5FoGcnrY"
                  frameborder="1"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
          >
          </iframe>
        </div>
        <div class="flex flex-wrap max-w-[816px] gap-2">
          <h2 class="text-4xl phone:text-2xl font-bold text-black max-w-[816px]" i18n>
            Service for business: Simple online customer registration.
          </h2>
          <div
            class="flex justify-between items-center phone:gap-5 w-full phone:portrait:flex-col portrait:w-[1024]:flex-row">
            <p class="text-base max-w-[500px] font-normal" i18n>
              Order a demo access to the online customer recording service, a convenient solution without registration.
            </p>
            <a [href]="demoAccountUrl" target="_blank" id="demo-button">
              <button class="font-bold text-[20px] py-4 px-7 bg-[#FFD429] rounded-[60px] w-full md:w-auto hover:bg-[#FFC800]
                             transition-all duration-150 ease-in-out active:scale-95" i18n
              >
                Demo account
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
    <app-tariffs/>
    <section id="faq"
             #faqList
             class="flex lg:h-[866px] w-full justify-center items-center phone:py-10 phone:px-5
                lg:px-5 xl:px-0"
    >
      <div class="w-full max-w-[1060px] flex gap-10 flex-col">
        <div class="relative bg-[#FFFFFF] z-10 pb-4">
          <h2 i18n="FAQ header|Short title for FAQ section" class="font-bold text-[40px] leading-10">FAQ</h2>
        </div>
        <div id="faq-list"
             class="relative flex flex-col transition-[min-height] duration-500 ease-in-out"
             [ngStyle]="{ 'min-height': faqMinHeight }"
        >
          @for (item of faqItems(); track item.title; let i = $index) {
            <div id="faq-item-{{ i }}" class="self-stretch justify-center items-start inline-flex w-full flex-col">
              <div (click)="toggleItem(i)" id="faq-button"
                   class="flex justify-between items-center w-full gap-5 pt-5 pb-5 cursor-pointer">
                <div class="grow text-black text-sm font-bold leading-tight" i18n="FAQ Title">
                  {{ item.title }}
                </div>
                <div class="flex items-center justify-center">
                  @if (activeIndex === i) {
                    <ng-icon name="bootstrapDashCircle" size="2.5em" />
                  } @else {
                    <ng-icon name="bootstrapPlusCircle" size="2.5em" />
                  }
                </div>
              </div>

              <div class="grid transition-[grid-template-rows] duration-500 ease-in-out"
                   [ngClass]="{
                 'grid-rows-[0fr] opacity-0': activeIndex !== i,
                 'grid-rows-[1fr] opacity-100': activeIndex === i
               }">
                <div class="overflow-hidden text-sm text-black pl-[30px] max-w-[1000px] phone:portrait:pr-[60px] leading-6">
                  <div class="mb-8">
                    <span class="font-bold">{{ item.content }}</span>
                    <span [innerHTML]="item.description"></span>
                  </div>

                  @if (item.list) {
                    @if (item.listType === 'ordered') {
                      <ol class="pl-2 mt-2.5 ml-4 list-decimal marker:font-bold mb-8">
                        @for (point of item.list; track point) {
                          <li>
                            <span class="font-bold">{{ point.split(':')[0] }}:</span>
                            {{ point.split(':')[1] }}
                          </li>
                        }
                      </ol>
                    } @else if (item.listType === 'unordered') {
                      <ul class="pl-2 mt-2.5 ml-3.5 list-disc mb-8">
                        @for (point of item.list; track point) {
                          <li>
                            <span class="font-bold">{{ point.split(':')[0] }}:</span>
                            {{ point.split(':')[1] }}
                          </li>
                        }
                      </ul>
                    } @else {
                      <div class="mt-2.5 list-none mb-8">
                        @for (point of item.list; track point) {
                          <p class="mb-2">
                            <span class="font-bold">{{ point.split(':')[0] }}:</span>
                            {{ point.split(':')[1] }}
                          </p>
                        }
                      </div>
                    }
                  }
                </div>
              </div>
              <div class="border-b border-[#cacaca] w-full"></div>
            </div>
          }
        </div>
      </div>
    </section>
    <section id="contact"
      class="flex w-full lg:max-h-[1006px] lg:h-full justify-center items-center phone:py-10 phone:px-5 lg:px-5 xl:px-0 mb-4 lg:py-40">
      <contact-form-component/>
    </section>
  </div>
  <footer class="bg-[#1E1E1E] w-full flex justify-center items-center">
    <div class="w-full h-[180px] phone:h-[140px] flex max-w-[1080px] px-2.5 py-2.5 justify-between items-center text-[#FFFFFF]
                phone:portrait:flex-col phone:portrait:items-start phone:portrait:py-2.5"
    >
      <div class="flex gap-2.5 phone:order-1 phone:portrait:gap-5">
        <a href="https://docs.beeoclock.com/" class="hover:underline underline-offset-2" target="_blank" i18n>Terms of use</a>
        <a href="https://docs.beeoclock.com/privacy-policy" class="hover:underline underline-offset-2" target="_blank" i18n>Privacy policy</a>
      </div>
      <div class="phone:order-3" i18n>
        © {{ currentYear }} Bee O'clock. All rights reserved.
      </div>
      <app-languages/>
    </div>
  </footer>
</div>
